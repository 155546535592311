/* @font-face {
  font-family: 'MyriadPro';
  src: local('MyriadPro'), url(./fonts/MyriadPro-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'Swiss';
  src: local('Swiss'), url(./fonts/swiss.ttf) format('truetype');
} */

:root {
 
  --brand-color-light: #595d40;
  --brand-color-dark: #323522;
  --brand-accent-color: #fc751b;

  --text: #fff;
  --app-bg: var(--brand-color-dark);

  --link: #fff;

}
